import React from 'react';
import Layout from '../templates/layout';
import useScreenSize from 'use-screen-size';
import TermsTransformer from '../components/terms/transformer';

const Privacy = (props) => {
  const screenSize = useScreenSize();
  return (
    <Layout
      buttonType='help'
      screenSize={screenSize}
      path={props.location.pathname}
    >
      <div className='flex py-[125px] px-[16px] '>
        <TermsTransformer terms={privacy} title={''} />
      </div>
    </Layout>
  );
};

const privacy = [
  {
    title: null,
    paragraphs: [
      'Privacy Policy for Careery',
      'Effective Date: Apr 7, 2023',
      'At Careery, we prioritize your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, store, and disclose your personal information when you use our services. By using our services, you agree to the collection and use of your information in accordance with this Privacy Policy.',
    ],
  },
  {
    title: 'Information Collection and Use',
    paragraphs: [
      'We collect several types of information to provide and improve our services, including but not limited to:',
      'a. Personal Information: When you register for our services or complete our TypeForm, we may ask you to provide certain personal information, including your name, email address, phone number, employment history, skills, and preferences. This information is used to create your resume, apply for jobs on your behalf, and provide personalized advice.',
      'b. Usage Data: We may collect information on how our website and services are accessed and used, such as the pages visited, time spent on the site, and other diagnostic data. This helps us improve the user experience and overall quality of our services.',
    ],
  },
  {
    title: 'Data Storage and Transfer',
    paragraphs: [
      "Your information, including Personal Data, is processed at Careery's operating offices and in any other locations where the parties involved in the processing are located. This means that your information may be transferred to — and maintained on — computers located outside your state, province, country, or other governmental jurisdiction where data protection laws may differ from those in your jurisdiction.",
      'Your consent to this Privacy Policy, followed by your submission of such information, represents your agreement to that transfer. Careery will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy, and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place, including the security of your data and other personal information.',
    ],
  },
  {
    title: 'Data Security',
    paragraphs: [
      'We are committed to protecting the security of your personal information. We use various security technologies and procedures to safeguard your personal information from unauthorized access, use, or disclosure. However, please be aware that no method of electronic transmission or storage is 100% secure, and we cannot guarantee the absolute security of your information.',
    ],
  },
  {
    title: 'Disclosure of Data',
    paragraphs: [
      'We may disclose your personal information if required to do so by law or in the good-faith belief that such action is necessary to comply with legal obligations, protect and defend our rights and property, or to protect the safety of our users and the public.',
    ],
  },
  {
    title: 'Access and Control of Personal Information',
    paragraphs: [
      'You have the right to access, update, or delete your personal information at any time. If you wish to exercise this right, please contact us at [email protected]',
    ],
  },
  {
    title: 'Changes to This Privacy Policy',
    paragraphs: [
      `We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Your continued use of our services after the posting of the modified Privacy Policy constitutes your acknowledgment of the changes and your consent to abide by the updated Privacy Policy.`,
    ],
  },
  {
    title: 'Cookies and Similar Technologies',
    paragraphs: [
      'We use cookies and similar tracking technologies to track the activity on our website and collect certain information. Cookies are small files that are placed on your device by websites you visit. They are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the site. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer.',
      'We may use both session and persistent cookies for various purposes, such as to:',
      'Remember your preferences and settings.',
      'Analyze the performance and effectiveness of our website and services.',
      'Personalize your experience on our website.',
      'Provide targeted advertising based on your browsing activity.',
      'You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our website or services.',
    ],
  },
  {
    title: 'Third-Party Websites',
    paragraphs: [
      "Our website may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's website. We strongly advise you to review the Privacy Policy of every site you visit.",
      'We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.',
    ],
  },
  {
    title: null,
    paragraphs: [
      'By using our services, you acknowledge that you have read, understood, and agreed to be bound by this Privacy Policy.',
    ],
  },
  {
    title: 'Contact Us',
    paragraphs: [
      'If you have any questions about this Privacy Policy or our privacy practices, please contact us at hi@careery.pro',
      'By using our services, you acknowledge that you have read, understood, and agreed to be bound by this Privacy Policy.',
    ],
  },
];

export default Privacy;
