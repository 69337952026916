import React from 'react';

const TermsTransformer = ({ terms, title }) => {
  return (
    <div className=''>
      <Title> {title} </Title>
      {terms.map(({ title, paragraphs }) => {
        return (
          <>
            {title && <Title>{title}</Title>}
            {paragraphs.map((p) => (
              <Paragraph>{p}</Paragraph>
            ))}
          </>
        );
      })}
    </div>
  );
};

const Title = (props) => (
  <h1 className='text-2xl font-bold mb-8'>{props.children}</h1>
);

const Paragraph = (props) => <p className='mb-4'>{props.children}</p>;
export default TermsTransformer;
